type ItemsCarouselProps = {
  children: JSX.Element | JSX.Element[];
};

const ItemsCarousel = ({ children }: ItemsCarouselProps) => {
  return (
    <div className="relative w-full flex flex-col items-center  justify-center bg-white">
      <div
        className="w-full  items-center md:justify-center  sm:justify-center overflow-x-auto flex space-x-4 px-4 py-4 snap-x snap-mandatory no-scrollbar mb-24"
        style={{ maxHeight: "75vh" }}
      >
        {children}
      </div>
    </div>
  );
};
export default ItemsCarousel;

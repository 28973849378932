import { useNavigate } from "react-router-dom";
import CloseIcon from "../assets/X.svg";
import { useCart } from "../context/CartContext";

export const CloseButton = () => {
  const navigate = useNavigate();
  const { clearCart } = useCart();

  const handleClick = (navigateTo: string) => {
    navigate(navigateTo);
    clearCart();
  };

  return (
    <button className="absolute top-2 right-4" onClick={() => handleClick("/")}>
      <img src={CloseIcon} alt="Microphone Icon" />
    </button>
  );
};

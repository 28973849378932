type ItemCardProps = {
  children: JSX.Element | JSX.Element[];
};

const ItemCard = ({ children }: ItemCardProps) => {
  return (
    <div className="md:snap-center  snap-center w-[321px]  bg-white rounded-lg shadow-lg border border-gray-200 flex-shrink-0 relative">
      {children}
    </div>
  );
};
export default ItemCard;

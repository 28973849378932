import React from "react";
import { DarkCartButton } from "./CartButton";
import { DefaultMicrophone } from "./MicrophoneButton";

interface FooterProps {
  onMute(): void;
  onUnMute(): void;
  onAddToCart(): void;
}

interface PTFooterProps {
  onAddToCart(): void;
}

export const DefaultFooter: React.FC<FooterProps> = ({
  onMute,
  onUnMute,
  onAddToCart,
}) => {
  return (
    <div className="absolute bottom-[30px] w-full flex justify-between px-8 py-4">
      <DefaultMicrophone onMute={onMute} onUnMute={onUnMute} />
      <DarkCartButton onAddToCart={onAddToCart} />
    </div>
  );
};

export const PTFooter: React.FC<PTFooterProps> = ({ onAddToCart }) => {
  return (
    <div className="absolute bottom-[30px] w-full flex justify-end px-8 py-4">
      <DarkCartButton onAddToCart={onAddToCart} />
    </div>
  );
};

import React from "react";
import Fingerprint from "../assets/Fingerprint.svg";
import MessageCircle from "../assets/Message-circle.svg";
import ActionButton from "./ActionButton";

const ActionButtons: React.FC = () => (
  <div className="flex  space-x-4 mb-16 max-w-sm px-4 justify-center items-center">
    <ActionButton
      icon={Fingerprint}
      label="Appuyez pour discuter"
      navigateTo="/pt-assistant-ui"
    />
    <ActionButton
      icon={MessageCircle}
      label="Discutez en direct"
      navigateTo="/assistant-ui"
    />
  </div>
);

export default ActionButtons;

import { Route, Routes } from "react-router-dom";
import "./App.css";
import AssistantPage from "./pages/AssistantPage";
import HomePage from "./pages/HomePage";
import PTAssistant from "./pages/PTAssistant";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/assistant-ui" element={<AssistantPage />} />
      <Route path="/pt-assistant-ui" element={<PTAssistant />} />
    </Routes>
  );
}

export default App;

import React from "react";
import { useNavigate } from "react-router-dom";

interface ActionButtonProps {
  icon: string;
  label: string;
  navigateTo: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  icon,
  label,
  navigateTo,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(navigateTo);
  };

  return (
    <button
      onClick={handleClick}
      className="flex w-[156px] pl-2 pr-4 py-2 items-center justify-start gap-2 rounded-2xl bg-white/70 shadow-custom flex-shrink-0"
    >
      <div className="p-2 rounded-md bg-pure-white flex items-center justify-center">
        <img src={icon} alt={label} className="w-10 h-6" />
      </div>
      <span className="text-dark-purple font-sans text-sm font-normal leading-normal text-left">
        {label}
      </span>
    </button>
  );
};

export default ActionButton;

import ValidateIcon from "../assets/Validate.svg";
export const AlreadyAddedButton = () => {
  return (
    <button
      disabled
      className={`flex items-center justify-center w-12 h-12 rounded-xl flex-shrink-0 bg-dark-custom-light-gray`}
    >
      <img src={ValidateIcon} alt="Add to cart Icon" className="w-8 h-8" />
    </button>
  );
};

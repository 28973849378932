import { FormattedItem, RealtimeClient } from "openai-realtime-api";

import { useEffect, useRef, useState } from "react";
import { CloseButton } from "../components/CloseButton";
import { PTFooter } from "../components/Footer";
import SuggestedItems from "../components/SuggestedItems";
import { useCart } from "../context/CartContext";
import { WavRecorder, WavStreamPlayer } from "../lib/wavtools/index.js";

const PTAssistant = () => {
  const { addToCart } = useCart();

  const [bubblePosition, setBubblePosition] = useState("center");
  const [data, setData] = useState("");
  const [showCart, setShowCart] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [items, setItems] = useState<FormattedItem[]>([]);
  const [isRecording, setIsRecording] = useState(false);
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isPressed, setIsPressed] = useState(false);
  const [pulseClass, setPulseClass] = useState("animate-pulseExpand");
  const [isAssistantSpeaking, setIsAssistantSpeaking] = useState(false);

  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef(
    new RealtimeClient({ url: process.env.REACT_APP_RELAY_SERVER_URL })
  );

  const isUnmounting = useRef(false);

  const startRecording = async () => {
    setIsRecording(true);
    setIsPressed(true);
    setBubblePosition("bottom");
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  const stopRecording = async () => {
    setIsRecording(false);
    setIsPressed(false);
    if (!data) {
      setBubblePosition("center");
    }
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.pause();
    client.createResponse();
  };

  useEffect(() => {
    let animationFrameId: any;

    const updatePulse = () => {
      const wavStreamPlayer = wavStreamPlayerRef.current;

      let assistantFrequency = 0;

      if (wavStreamPlayer && wavStreamPlayer.analyser) {
        assistantFrequency = wavStreamPlayer.getAverageFrequency();
      }

      if (assistantFrequency > 0) {
        const scaleValue = Math.min(1 + assistantFrequency / 128, 1.5);
        setPulseClass(`scale(${scaleValue})`);
      } else if (isPressed) {
        setPulseClass("animate-fastPulseExpand");
      } else {
        setPulseClass("animate-pulseExpand");
      }

      animationFrameId = requestAnimationFrame(updatePulse);
    };

    updatePulse();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [isPressed]);

  useEffect(() => {
    const connectConversation = async () => {
      if (isConnected || isUnmounting.current) return;
      try {
        const client = clientRef.current;
        const wavRecorder = wavRecorderRef.current;
        const wavStreamPlayer = wavStreamPlayerRef.current;

        if (isConnected || client.isConnected) {
          return;
        }

        if (!client.isConnected) {
          await client.realtime.connect();
        }

        setItems(client.conversation.getItems());

        if (wavRecorder.getStatus() === "ended") {
          await wavRecorder.begin();
        }

        if (
          !wavStreamPlayer.context ||
          wavStreamPlayer.context.state === "closed"
        ) {
          await wavStreamPlayer.connect();
        }

        client.sendUserMessageContent([
          {
            type: `input_text`,
            text: `Salut!`,
          },
        ]);

        setIsConnected(true);
      } catch (error) {
        console.error("Failed to connect:", error);
        setIsConnected(false);
      }
    };

    const disconnectConversation = async () => {
      isUnmounting.current = true;
      try {
        const client = clientRef.current;
        const wavRecorder = wavRecorderRef.current;
        const wavStreamPlayer = wavStreamPlayerRef.current;

        if (!isConnected || !client.isConnected) {
          return;
        }

        if (wavRecorder.getStatus() === "recording") {
          await wavRecorder.pause();
        }

        if (wavRecorder.getStatus() !== "ended") {
          await wavRecorder.quit();
        }

        if (
          wavStreamPlayer.context &&
          wavStreamPlayer.context.state !== "closed"
        ) {
          await wavStreamPlayer.interrupt();
        }

        if (client.isConnected) {
          client.realtime.disconnect();
        }

        setIsConnected(false);
        setItems([]);
      } catch (error) {
        console.error("Failed to disconnect:", error);
      }
    };

    connectConversation();

    return () => {
      disconnectConversation();
    };
  }, [isConnected]);

  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    client.updateSession({
      input_audio_transcription: { model: "whisper-1" },
      turn_detection: null,
    });

    client.addTool(
      {
        name: "add_to_cart",
        description:
          "Ajoute le produit demandé au panier en envoyant son identifiant, utilise bien l'attribut 'id'",
        parameters: {
          type: "object",
          properties: {
            id: {
              type: "string",
              description: "Identifiant (ID) du produit à ajouter",
            },
          },
          required: ["id"],
        },
      },
      async ({ id }) => {
        addToCart(id);
      }
    );

    client.on("error", (event: any) => console.error(event));
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });

    wavStreamPlayer.onPlaybackEnd = () => {
      setBubblePosition("end");
      setIsAssistantSpeaking(false);
      //setIsPressed(false);
    };

    client.on("conversation.updated", async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        setIsAssistantSpeaking(true);
        if (bubblePosition !== "center" && !data) {
          setBubblePosition("center");
        }
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }

      if (
        item.type === "function_call_output" &&
        item.formatted.output !== "[]"
      ) {
        setData(item.formatted.output);
        setBubblePosition("bottom");
      }

      if (item.status === "completed" && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }

      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      client.reset();
    };
  }, []);

  const onAddToCart = () => {
    console.log("add product to cart"); // will handle add Tocart
  };

  return (
    <div className="relative w-full h-screen-safe   pb-[calc(16px+env(safe-area-inset-bottom))] flex justify-center items-center overflow-hidden">
      {data && <SuggestedItems data={data} />}
      <CloseButton />

      {/* Bubble Layer */}
      {bubblePosition !== "center" && !isPressed && !isAssistantSpeaking && (
        <span className="absolute text-gray-600 text-md font-medium bottom-[120px]">
          Appuyez pour parler
        </span>
      )}

      <div
        className={`bubble-gradient focus:outline-none focus:ring-0 focus:no-underline absolute z-50	${
          bubblePosition === "center"
            ? "animate-moveAndResizeToCenter"
            : "animate-moveAndResizeToBottom"
        }`}
        style={{
          bottom: bubblePosition === "center" ? "50%" : "50px",
          height: bubblePosition === "center" ? "121px" : "55.10px",
          width: bubblePosition === "center" ? "121px" : "55.10px",
          transform:
            bubblePosition === "center" ? "translateY(50%)" : "translateY(0)",
        }}
      >
        <div
          className={`focus:outline-none focus:ring-0 focus:no-underline absolute w-full h-full bg-gradient-to-r from-[#FF944D] via-[#FB81BA] to-[#D98BFB] rounded-full opacity-50 ${pulseClass}`}
          style={{
            transform: pulseClass.startsWith("scale") ? pulseClass : undefined,
            transition: pulseClass.startsWith("scale")
              ? "transform 0.1s ease-in-out"
              : undefined,
            background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
          }}
        ></div>
        <button
          onMouseDown={() => {
            if (bubblePosition !== "center") startRecording();
          }}
          onMouseUp={() => {
            if (bubblePosition !== "center") stopRecording();
          }}
          onTouchStart={() => {
            if (bubblePosition !== "center") startRecording();
          }}
          onTouchEnd={() => {
            if (bubblePosition !== "center") stopRecording();
          }}
          disabled={isAssistantSpeaking}
          className={`absolute focus:outline-none focus:ring-0 focus:no-underline bg-gradient-to-r from-[#FF944D] via-[#FB81BA] to-[#D98BFB] rounded-full shadow-inner blur-md  opacity-65`}
          style={{
            height: "100%",
            width: "100%",
            background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
          }}
        ></button>
      </div>

      <PTFooter onAddToCart={onAddToCart} />
    </div>
  );
};

export default PTAssistant;

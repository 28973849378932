import React, { useEffect, useState } from "react";
import { Product as ProductModel } from "../types/product";
import { Style as StyleModel } from "../types/types";
import Product from "./Product";
import Styles from "./Styles";

interface SuggestedItemsProps {
  data: string;
}

type ItemsType = StyleModel[] | ProductModel[];

const SuggestedItems: React.FC<SuggestedItemsProps> = ({ data }) => {
  const [parsedData, setParsedData] = useState<
    ProductModel[] | StyleModel[] | null
  >(null);
  useEffect(() => {
    try {
      if (data) {
        const parsed = JSON.parse(data) as ProductModel[] | StyleModel[];
        setParsedData(parsed);
      }
    } catch (error) {
      console.error("Failed to parse data JSON:", error);
      setParsedData(null);
    }
  }, [data]);

  const isProduct = (data: ItemsType): data is ProductModel[] => {
    return (data[0] as ProductModel).amount !== undefined;
  };

  if (!parsedData) {
    return <div>Invalid product data</div>;
  }

  return (
    <>
      {isProduct(parsedData) ? (
        <Product productData={parsedData as ProductModel[]} />
      ) : (
        <Styles styleData={parsedData as StyleModel[]} />
      )}
    </>
  );
};

export default SuggestedItems;

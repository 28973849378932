import React from "react";
import Frame2Icon from "../assets/Frame2.svg";

const Logo: React.FC = () => (
  <div className="flex items-center justify-center mb-4">
    <img src={Frame2Icon} alt="Logo" className="w-16 h-16" />
  </div>
);

export default Logo;

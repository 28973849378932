import React from "react";

const Greeting: React.FC = () => (
  <div>
    <p className="text-dark-purple font-sans text-lg mb-2">Bonjour Jean</p>
    <p className="text-dark-purple mb-8">
      Comment puis-je vous aider aujourd'hui ?
    </p>
  </div>
);

export default Greeting;

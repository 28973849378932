import { RealtimeClient } from "openai-realtime-api";

import { useEffect, useRef, useState } from "react";
import { CloseButton } from "../components/CloseButton";
import { DefaultFooter } from "../components/Footer";
import SuggestedItems from "../components/SuggestedItems";
import { useCart } from "../context/CartContext";
import { WavRecorder, WavStreamPlayer } from "../lib/wavtools/index.js";

const AssistantPage = () => {
  const { addToCart } = useCart();
  const [data, setData] = useState("");
  const [bubblePosition, setBubblePosition] = useState("center");
  const [isConnected, setIsConnected] = useState(false);
  const [pulseClass, setPulseClass] = useState("animate-pulseExpand");
  const [showText, setShowText] = useState(true);
  const [isActiveSpeaker, setIsActiveSpeaker] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSpoken, setHasSpoken] = useState(false);
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef(
    new RealtimeClient({ url: process.env.REACT_APP_RELAY_SERVER_URL })
  );

  const isUnmounting = useRef(false);

  useEffect(() => {
    let inactivityTimer: any = null;
    let animationFrameId: any;
    const threshold = 10; // frequencies < 10 are user very quit frequencies
    const inactivityDuration = 2000;
    const updatePulse = () => {
      const wavRecorder = wavRecorderRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;

      let userFrequency = 0;
      let assistantFrequency = 0;

      if (wavRecorder && wavRecorder.analyser) {
        userFrequency = wavRecorder.getAverageFrequency();
      }

      if (wavStreamPlayer && wavStreamPlayer.analyser) {
        assistantFrequency = wavStreamPlayer.getAverageFrequency();
      }

      const isCurrentlySpeaking =
        userFrequency >= threshold || assistantFrequency > 0;

      setIsActiveSpeaker(isCurrentlySpeaking);

      if (isCurrentlySpeaking) {
        const scaleValue = Math.min(
          1 + Math.max(userFrequency, assistantFrequency) / 128,
          1.5
        );
        if (inactivityTimer) {
          clearTimeout(inactivityTimer);
          inactivityTimer = null;
        }
        setShowText(false);
        setPulseClass(`scale(${scaleValue})`);
      } else if (!inactivityTimer) {
        setPulseClass("animate-pulseExpand");
        inactivityTimer = setTimeout(() => {
          setShowText(true); // Show the message after 2 seconds
        }, inactivityDuration);
      }

      animationFrameId = requestAnimationFrame(updatePulse);
    };

    updatePulse();

    return () => {
      cancelAnimationFrame(animationFrameId);
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }
    };
  }, []);

  useEffect(() => {
    const connectConversation = async () => {
      if (isConnected || isUnmounting.current) return;
      try {
        const client = clientRef.current;
        const wavRecorder = wavRecorderRef.current;
        const wavStreamPlayer = wavStreamPlayerRef.current;

        if (isConnected || client.isConnected) {
          return;
        }

        if (!client.isConnected) {
          await client.realtime.connect();
          await client.waitForSessionCreated();
        }

        if (wavRecorder.getStatus() === "ended") {
          await wavRecorder.begin();
        }

        if (wavRecorder.getStatus() === "paused") {
          await wavRecorder.record((data) =>
            client.appendInputAudio(data.mono)
          );
        }

        if (
          !wavStreamPlayer.context ||
          wavStreamPlayer.context.state === "closed"
        ) {
          await wavStreamPlayer.connect();
        }

        client.sendUserMessageContent([
          {
            type: `input_text`,
            text: `Salut!`,
          },
        ]);
        setIsConnected(true);
      } catch (error) {
        console.error("Failed to connect:", error);
        setIsConnected(false);
      }
    };

    const disconnectConversation = async () => {
      isUnmounting.current = true;
      try {
        const client = clientRef.current;
        const wavRecorder = wavRecorderRef.current;
        const wavStreamPlayer = wavStreamPlayerRef.current;

        if (!isConnected || !client.isConnected) {
          return;
        }

        if (wavRecorder.getStatus() === "recording") {
          await wavRecorder.pause();
        }

        if (wavRecorder.getStatus() !== "ended") {
          await wavRecorder.quit();
        }

        if (
          wavStreamPlayer.context &&
          wavStreamPlayer.context.state !== "closed"
        ) {
          await wavStreamPlayer.interrupt();
        }

        if (client.isConnected) {
          client.realtime.disconnect();
        }
        setIsConnected(false);
      } catch (error) {
        console.error("Failed to disconnect:", error);
      }
    };

    connectConversation();

    return () => {
      disconnectConversation();
    };
  }, [isConnected]);

  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    client.addTool(
      {
        name: "add_to_cart",
        description:
          "Ajoute le produit au panier en envoyant son identifiant, utilise bien l'attribut 'id'",
        parameters: {
          type: "object",
          properties: {
            id: {
              type: "string",
              description: "Identifiant (ID) du produit à ajouter",
            },
          },
          required: ["id"],
        },
      },
      async ({ id }) => {
        addToCart(id);
      }
    );

    client.on("error", (event: any) => console.error(event));
    client.on("conversation.interrupted", async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });

    const handleConversationUpdate = async ({ item, delta }: any) => {
      if (delta?.audio) {
        setIsLoading(false);
        wavStreamPlayerRef.current.add16BitPCM(delta.audio, item.id);
        if (!hasSpoken) {
          setTimeout(() => {
            setHasSpoken(true);
          }, 1000);
        }
      }
      if (item.type === "function_call") {
        setIsLoading(true);
      }

      if (
        item.type === "function_call_output" &&
        item.formatted.output !== "[]"
      ) {
        setData(item.formatted.output);
        setIsLoading(false);
        setBubblePosition("bottom");
        setShowText(false);
      }

      if (item.status === "completed" && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
    };

    client.on("conversation.updated", handleConversationUpdate);

    return () => {
      client.off("conversation.updated", handleConversationUpdate);
    };
  }, []);

  const onAddToCart = () => {
    console.log("add product to cart");
  };

  const onMute = async () => {
    try {
      const wavRecorder = wavRecorderRef.current;

      if (wavRecorder.getStatus() === "recording") {
        await wavRecorder.pause();
      }
    } catch (error) {
      console.log("error while muting", error);
    }
  };

  const onUnMute = async () => {
    try {
      const wavRecorder = wavRecorderRef.current;
      if (wavRecorder.getStatus() === "paused") {
        await wavRecorder.record((data) =>
          clientRef.current.appendInputAudio(data.mono)
        );
      }
    } catch (error) {
      console.log("error while unmuting");
    }
  };

  return (
    <div className="relative w-full h-screen-safe   pb-[calc(16px+env(safe-area-inset-bottom))] flex justify-center items-center overflow-hidden">
      {/*<div className="absolute w-full h-[30px] top-0 p-4 mt-0">*/}
      <CloseButton />
      {/*</div>*/}
      {data && <SuggestedItems data={data} />}
      {showText && bubblePosition !== "center" && !isLoading && (
        <span className="absolute text-gray-600 text-md font-medium bottom-[120px]">
          Je vous écoute...
        </span>
      )}
      {isLoading && bubblePosition !== "center" && (
        <span className="absolute text-gray-600 text-md font-medium bottom-[120px]">
          Patientez...
        </span>
      )}
      <div
        className={`bubble-gradient absolute ${
          bubblePosition === "center"
            ? "animate-moveAndResizeToCenter"
            : "animate-moveAndResizeToBottom"
        }`}
        style={{
          bottom: bubblePosition === "center" ? "50%" : "50px",
          height: bubblePosition === "center" ? "121px" : "55.10px",
          width: bubblePosition === "center" ? "121px" : "55.10px",
          transform:
            bubblePosition === "center" ? "translateY(50%)" : "translateY(0)",
        }}
      >
        <div
          className={`absolute w-full h-full bg-gradient-to-r  from-[#ff944d] via-[#fb80b9] to-[#D98BFB] rounded-full opacity-50 ${pulseClass}`}
          style={{
            transform: pulseClass.startsWith("scale") ? pulseClass : undefined,
            transition: pulseClass.startsWith("scale")
              ? "transform 0.1s ease-in-out"
              : undefined,
            background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
          }}
        ></div>
        <div
          className="absolute w-full h-full bg-gradient-to-r  from-[#ff944d] via-[#fb80b9] to-[#D98BFB] rounded-full  blur-md shadow-inner opacity-65"
          style={{
            background: `linear-gradient(135deg, #FF944D 0%, #FB81BA 33.33%, #D98BFB 66.67%, #FF944D 100%)`,
          }}
        ></div>
      </div>
      {showText && bubblePosition === "center" && !isLoading && hasSpoken && (
        <span className="absolute text-gray-600 text-md font-medium bottom-[30%]">
          Je vous écoute...
        </span>
      )}
      {isLoading && bubblePosition === "center" && (
        <span className="absolute text-gray-600 text-md font-medium bottom-[30%]">
          Patientez...
        </span>
      )}

      <DefaultFooter
        onMute={onMute}
        onUnMute={onUnMute}
        onAddToCart={onAddToCart}
      />
    </div>
  );
};

export default AssistantPage;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DarkMicIcon from "../assets/DarkMic.svg";
import LightMicIcon from "../assets/LightMic.svg";
import MicOffIcon from "../assets/MicOff.svg";

interface MicrophoneButtonProps {
  icon: string;
  bgColor: string;
  navigateTo: string;
}

const MicrophoneButton: React.FC<MicrophoneButtonProps> = ({
  icon,
  bgColor,
  navigateTo,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(navigateTo);
  };

  return (
    <button
      onClick={handleClick}
      className={`flex items-center justify-center w-16 h-16 rounded-xl flex-shrink-0 ${bgColor}`}
    >
      <img src={icon} alt="Microphone Icon" className="w-8 h-8" />
    </button>
  );
};

export const DarkMicrophoneButton: React.FC = () => (
  <MicrophoneButton
    icon={DarkMicIcon}
    bgColor="bg-dark-purple"
    navigateTo="/assistant-ui"
  />
);

export const LightMicrophoneButton: React.FC = () => (
  <MicrophoneButton
    icon={LightMicIcon}
    bgColor="bg-dark-custom-light-gray"
    navigateTo="/"
  />
);

interface DefaultMicrophoneProps {
  onMute(): void;
  onUnMute(): void;
}

export const DefaultMicrophone: React.FC<DefaultMicrophoneProps> = ({
  onMute,
  onUnMute,
}) => {
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    if (isMuted) {
      onMute();
    } else {
      onUnMute();
    }
  }, [isMuted]);

  const handleMicClick = () => {
    setIsMuted((prevIsMuted) => !prevIsMuted);
  };

  return (
    <button
      onClick={handleMicClick}
      className={`flex items-center justify-center w-16 h-16 rounded-xl flex-shrink-0 bg-dark-custom-light-gray`}
    >
      {isMuted ? (
        <img src={MicOffIcon} alt="Microphone Icon" className="w-8 h-8" />
      ) : (
        <img src={LightMicIcon} alt="Microphone Icon" className="w-8 h-8" />
      )}
    </button>
  );
};

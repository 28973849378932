import React from "react";
import ActionButtons from "../components/ActionButtons";
import Greeting from "../components/Greeting";
import Logo from "../components/Logo";

const HomePage: React.FC = () => {
  return (
    <div className=" w-full relative flex flex-col items-center justify-center h-screen-safe   pb-[calc(16px+env(safe-area-inset-bottom))] text-center  sm:pb-10 overflow-hidden flex-shrink-0  top-0 mt-0">
      <div className="bg-radial-gradient top-0 mt-0"></div>
      <Logo />
      <Greeting />
      <ActionButtons />
      <footer className="absolute bottom-6 text-gray-500 text-sm">
        Powered by <span className="font-semibold">Orkester</span> x{" "}
        <span className="font-semibold">Commercetools</span> x{" "}
        <span className="font-semibold">OpenAI</span>
      </footer>
    </div>
  );
};

export default HomePage;
